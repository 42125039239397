import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`1. HostJane Marketplace Definitions`}</h2>
    <p><strong parentName="p">{`1.1 "Skill"`}</strong>{` is a User's capability to perform a task or action of value to a Buyer, that can be digitally delivered in a non-tangible (virtual) form over the internet.`}</p>
    <p><strong parentName="p">{`1.2 "Service"`}</strong>{` is the process of delivering value to the Buyer. Services are performed by Sellers who facilitate an outcome that will be of value to the Buyer, employing specific Skills, costs, and risks that the Buyer cannot reach without paying the Seller.`}</p>
    <p><strong parentName="p">{`1.3 "Final Work"`}</strong>{` means the coherent, ready-to-use deliverable that a Seller used their Skills to create as part of their Service. Buyers receive their unwatermarked, non-limited Final Work after Buyer leaves feedback and approves release of payment to Seller.`}</p>
    <p><strong parentName="p">{`1.4 "Work Preview"`}</strong>{` depending on type of Work performed as given in Section 2.14, means the watermarked, abridged, or restricted audiovisual preview of the Final Work; or the confirmation of attendance in an online booking, that Sellers provide to Buyers during the Delivery Stage for the purposes of attaining Buyer's approval, feedback, and subsequent release of payment to Seller. `}</p>
    <p><strong parentName="p">{`1.5 "Buyers"`}</strong>{` are private Users who purchase Services from other Users through HostJane.`}</p>
    <p><strong parentName="p">{`1.5a "Freelancers"`}</strong>{` are Users who operate a public business profile located on HostJane.com`}</p>
    <p><strong parentName="p">{`1.6 "Sellers"`}</strong>{` are Users who create Listings and offer or sell Services through HostJane. It is 100% free to Sell a Service on HostJane, there are no setup fees, no Listing fees, no monthly fees and no ongoing fees.`}</p>
    <p><strong parentName="p">{`1.7 "Listings"`}</strong>{` are page descriptions of Skills offered and sold by Users as Services through HostJane. Each Listing must serve a Skill related to one of HostJane's five categories; (i) Web, mobile and technology; (ii) Video, voice, art and design; (iii) Online bookings and teaching; (iv) Writing and translation; (v) Business, digital marketing and accounting. The Service can be sold giving Buyer all rights to the delivered Work, or with an ad hoc License to use the delivered Work determined by the Seller before any purchase. The minimum value for the sale of any Skill through HostJane is USD $1.`}</p>
    <p><strong parentName="p">{`1.8 "Orders"`}</strong>{` are financial considerations paid in U.S. dollars from Buyers to Sellers on HostJane to commence Work based on the legal agreement and information available on the Seller's Listing page or Custom Offer. All prices and payments on HostJane are in U.S. dollars.`}</p>
    <p><strong parentName="p">{`1.9 "Service Cost"`}</strong>{` is the price that the Seller sets for their Service. This must include the Marketplace Fee. HostJane uses Seller-driven pricing, Seller's control the prices of their Listings.`}</p>
    <p><strong parentName="p">{`1.10 "Base Price"`}</strong>{` is the minimum price the Buyer pays for a Seller's Listing. The Base Price is made up of the Service Cost and the Handling Fee. The Base Price excludes Service add-ons and Licensing costs, which are both priced by the Seller, and, if applicable, Non-Disclosure Agreement admin fees. `}</p>
    <p><strong parentName="p">{`1.11 "Profiles"`}</strong>{` are non-publicly accessible member pages for Users who have not set up a Listing. Profiles can only be accessed by current HostJane members.`}</p>
    <p><strong parentName="p">{`1.12 "Studios"`}</strong>{` are Seller portfolios of offered Services or public business profiles operated by Freelancers. After User has set up at least 1 Listing, the User's profile is transformed into a publicly-accessible Studio at the address based on the Seller's Username and located at: `}<a parentName="p" {...{
        "href": "http://www.hostjane.com/marketplace/username"
      }}>{`www.hostjane.com/marketplace/username`}</a></p>
    <p><strong parentName="p">{`1.13 "License"`}</strong>{` is a legal agreement between the Buyer and Seller for how the deliverable Final Work can be used by the Buyer.`}</p>
    <p><strong parentName="p">{`1.14 "Delivery Time"`}</strong>{` is the deadline specified by the Seller on the Listing page by which time the Service must be delivered to the Buyer otherwise the Buyer receives a refund less the Handling Fee. `}</p>
    <p><strong parentName="p">{`1.15 "Online Bookings"`}</strong>{` are orders based on Listings where the Seller offers a Skill to be provided over one-to-one video call such as Zoom, Google Meet, WhatsApp, Telegram or Skype, for example, an online lesson. Buyers and Sellers liaise using HostJane's messaging system to schedule a booking based on their timezones, which must be held within the delivery time of the Service as given by the Seller on the Listing or Booking page.`}</p>
    <p><strong parentName="p">{`1.16 "Boards"`}</strong>{` are interactive collaboration tools that are fully integrated with site messaging to assist Buyers and Sellers to collaborate on tasks. Boards feature a cloud-based system of lists and sticky notes called cards that can be mutually organized by both Buyer and Seller with work notifications of changes delivered to each User's email and account. Each card stores documents, files, comments, attachments, photos, checklists and text. Each order including custom orders and online bookings are assigned 1 Board that becomes available after a Seller accepts a Buyer's order. `}</p>
    <p><strong parentName="p">{`1.17 "Buyer Payment Protection"`}</strong>{` means the holding of Buyer funds by HostJane until Seller delivers purchased Service, on time, and meeting the Buyer's Satisfaction. If Work Preview is delivered late by Seller or is not unsatisfactory, the Order is eligible for a refund to Buyer under HostJane's Purchase Protection in `}<strong parentName="p">{`2.1.2`}</strong>{`. HostJane acts as Sellers' limited authorized payment collection agent.`}</p>
    <p><strong parentName="p">{`1.18 "Completed Order"`}</strong>{` is an Order where Buyer has successfully received their Final Work from Seller, having approved release funds to the Seller and left public feedback for the Seller.`}</p>
    <p><strong parentName="p">{`1.19 "Custom Orders"`}</strong>{` are customized Orders created by Sellers for Buyers through the HostJane internal messaging system. The same terms and conditions on regular Orders apply to custom Orders.`}</p>
    <p><strong parentName="p">{`1.20 "Tips"`}</strong>{` are voluntary payments sent by Buyers to Sellers in appreciation of Work performed on HostJane. Tips can be initiated from the Messaging system.`}</p>
    <p><strong parentName="p">{`1.21 "Seller Percentage"`}</strong>{` is the fraction of the Service Cost that the Seller retains per order. The standard Seller Percentage is 95% of the Service Cost which is set by the Seller.`}</p>
    <p><strong parentName="p">{`1.22 "Earnings"`}</strong>{` are net funds that Sellers retain from Completed Orders for withdrawal to Seller's bank or use for purchase of other Seller's Listings offered on HostJane. `}</p>
    <p><strong parentName="p">{`1.23 "Waiting Period"`}</strong>{` is the five (5) days period of time between the date the Buyer approves release of funds to Seller in a Completed Order, to the date the specified net funds can be withdrawn from the Site to Seller's bank.`}</p>
    <p><strong parentName="p">{`1.24 "HostJane Balance"`}</strong>{` is the total amount of net Earnings in US dollars that a User has accumulated through Sales of listings added to the net amount of returned payments from cancelled orders. If User balance is in credit, User can at any time request a refund of existing monies to User's bank or payment source less any owed funds.`}</p>
    <p><strong parentName="p">{`1.25 "Payment Services Provider(s)"`}</strong>{` mean financial service providers that supply the Service with a method for HostJane to collect US dollar payments from Buyers on behalf of Sellers, and for Sellers to receive their net Earnings in US dollars.`}</p>
    <p><strong parentName="p">{`1.26 "Marketplace Fee"`}</strong>{` is the fee charged by HostJane to the Seller each time the Seller's Balance is credited with funds from a completed sale of Listing, Custom Order or Tip. The standard Marketplace Fee is 5% on the Service Cost of Listings and Custom Orders and on Tips. The Marketplace Fee helps HostJane pay for marketing, hosting and all card payment fees between Buyers and Sellers.`}</p>
    <p><strong parentName="p">{`1.27 "Handling Fee"`}</strong>{` is a per-order, non-refundable, administrative and management fee of $1.99 USD charged by HostJane to the Buyer when the Buyer orders a Seller's Listing or accepts a Custom Offer by placing funds on hold with HostJane. Handling Fee is $1.99 USD irregardless of the amount of the transaction or the final status of the resulting Order. Handling Fees are not charged on Tips. The Handling Fee helps HostJane pay for platform costs including marketing, hosting and 24/7, 365 days customer service.`}</p>
    <p><strong parentName="p">{`1.28 "Disputes"`}</strong>{` are arbitrated disagreements between Buyers and Sellers resulting from an Order in any status. The Disputes process follows the procedures and rules described in `}<strong parentName="p">{`2.7`}</strong>{`. Both sides are asked to engage in the process and provide information or evidence before a fair decision is reached by HostJane within three (3) business days of the Dispute being raised. Both parties can initiate disputes from a past or present Order with no time limits. Disputes are assigned a case ID while they are being investigated and resolved by HostJane.`}</p>
    <p><strong parentName="p">{`1.29 "Random Acts of Kindness"`}</strong>{` are selfless good deeds provided by Buyers to Sellers or Freelancers to benefit Seller or Freelancer's mental health well-being and assist with her or his living costs (i.e. rent, gas, groceries) as an end in itself, without requiring any form of reciprocation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      